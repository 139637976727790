import React, { useState, useRef, useEffect } from 'react';
import { Stage, Group, Layer, Rect, Circle, Line, Arrow, Text, Image as KonvaImage, Transformer, Arc } from 'react-konva';
import { saveAs } from 'file-saver';
import "./Canvas.css";


import Konva from 'konva'
import { createClient } from '@supabase/supabase-js'
import OutlinedInput from '@mui/material/OutlinedInput';

import {
  Box,
  IconButton,
  Button,
  Toolbar,
  Checkbox,
  AppBar,
  MenuItem,
  Menu,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Select,
  FormControl,
 InputLabel
} from '@mui/material';

import Popover from '@mui/material/Popover';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


import SearchIcon from '@mui/icons-material/Search';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import ExportIcon from '@mui/icons-material/GetApp';
import GridOnIcon from '@mui/icons-material/GridOn';
import GridOffIcon from '@mui/icons-material/GridOff';
import RectangleIcon from '@mui/icons-material/Rectangle';
import CircleIcon from '@mui/icons-material/Circle';
import LineWeightIcon from '@mui/icons-material/LineWeight';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import FormatShapesIcon from '@mui/icons-material/FormatShapes';
import NatureIcon from '@mui/icons-material/Nature';
import BoltIcon from '@mui/icons-material/Bolt';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import SpeedIcon from '@mui/icons-material/Speed';
import PetsIcon from '@mui/icons-material/Pets';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import CreateIcon from '@mui/icons-material/Create';
import PanToolIcon from '@mui/icons-material/PanTool';
import MenuIcon from '@mui/icons-material/Menu';
import ImageIcon from '@mui/icons-material/Image';
import PoolIcon from '@mui/icons-material/Pool';
import GateIcon from '@mui/icons-material/MeetingRoom';
import MapIcon from '@mui/icons-material/Map';
// Import images
import electricityImageSrc from './images/electricity.png';
import solarPanelImageSrc from './images/solar.svg';
import gasMeterImageSrc from './images/gasMeter.svg';
import dogImageSrc from './images/dog.svg';
import slideMotorImageSrc from './images/slideMotor.svg';
import { Upload } from '@mui/icons-material';
import { decode } from 'base64-arraybuffer'

import axios from 'axios';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
const Canvas = () => {
  const [lines, setLines] = useState([]);
  const [vvv,setvvv]= useState(50);
  const [mainMenu,setMainMenu] = useState(false);
  const [isDrawing, setIsDrawing] = useState(false);
  const [showGrid, setShowGrid] = useState(true);
  const [selectedShape, setSelectedShape] = useState('hand');
  const [shapes, setShapes] = useState([]);
  const [currentShape, setCurrentShape] = useState(null);
  const [history, setHistory] = useState([]);
  const [redoHistory, setRedoHistory] = useState([]);
  const [textInput, setTextInput] = useState('');
  const [searchInput, setSearchInput] = useState('');
  const [uploadedImage, setUploadedImage] = useState(null);
  const [scale, setScale] = useState({ x: 1, y: 1 });
  const [selectedId, setSelectedId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [fenceAnchorEl, setFenceAnchorEl] = useState(null);
  const [gateAnchorEl, setGateAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [mapDialogOpen, setMapDialogOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);
  const [mapAddress, setMapAddress] = useState('');
  const [mapWidth, setMapWidth] = useState(400);
  const [mapHeight, setMapHeight] = useState(400);
  const [mapType, setMapType] = useState('roadmap');
  const [mapImage, setMapImage] = useState(null);
  const [images, setImages] = useState({});
  const [selectedType, setSelectedType] = useState(null); // Add this line

  const [email,setEmail] = useState('');
  const [password, setPassword]  = useState('');
  const [currentLocation, setCurrentLocation] = useState('');
  const [currentToken, setCurrentToken] = useState('');
  const [currentContact,setCurrentContact]  = useState('');
  const [isInvalid,setIsInvalid] = useState("block");
  const [mode, setMode] = useState('');

  function createData(
    name: string,
    calories: number,
    fat: number,
    carbs: number,
    protein: number,
  ) {
    return { name, calories, fat, carbs, protein };
  }



  const filterOptions = createFilterOptions({
    // remove this line here
    limit: 5,
    
     stringify: (option) => JSON.stringify(option)
   });
  
const rows = [];
const supabaseUrl = 'https://qiwqbswgenqoyhpodedr.supabase.co'
const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InFpd3Fic3dnZW5xb3locG9kZWRyIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjQ3NDEyMzksImV4cCI6MjA0MDMxNzIzOX0.9lwg87U2tXSlbNpQqVtrOnx0wA90mpFOP9eaoIVV-pI"
const supabase = createClient(supabaseUrl, supabaseKey);

  useEffect(() => {

   
    if(window.localStorage.getItem("islogged")){
      setCurrentContact(window.localStorage.getItem("currentContact"));
      setCurrentLocation(window.localStorage.getItem("currentLocation"));
      setCurrentToken(window.localStorage.getItem("currentToken"));

    }
    else{
      setLoginOpen(true);
    }


    const loadImages = async () => {
      const electricityImage = new window.Image();
      const solarPanelImage = new window.Image();
      const gasMeterImage = new window.Image();
      const dogImage = new window.Image();
      const slideMotorImage = new window.Image();
  
      electricityImage.src = electricityImageSrc;
      solarPanelImage.src = solarPanelImageSrc;
      gasMeterImage.src = gasMeterImageSrc;
      dogImage.src = dogImageSrc;
      slideMotorImage.src = slideMotorImageSrc;
  
      await Promise.all([
        new Promise((resolve) => { electricityImage.onload = resolve; }),
        new Promise((resolve) => { solarPanelImage.onload = resolve; }),
        new Promise((resolve) => { gasMeterImage.onload = resolve; }),
        new Promise((resolve) => { dogImage.onload = resolve; }),
        new Promise((resolve) => { slideMotorImage.onload = resolve; }),
      ]);
  
      setImages({
        electricity: electricityImage,
        solarPanel: solarPanelImage,
        gasMeter: gasMeterImage,
        dog: dogImage,
        slideMotor: slideMotorImage,
      });
    };
  
    loadImages();
  }, []);

  const shapeRefs = useRef([]);

const TransformerComponent = ({ selectedShapeName }) => {
  const transformerRef = useRef();

  useEffect(() => {
    const stage = transformerRef.current.getStage();
    const selectedNode = stage.findOne(`.${selectedShapeName}`);
    if (selectedNode) {
      transformerRef.current.nodes([selectedNode]);
      transformerRef.current.getLayer().batchDraw();
    } else {
      transformerRef.current.nodes([]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [selectedShapeName]);

  return <Transformer ref={transformerRef} />;
};


  const handleWheel = (e) => {
    e.evt.preventDefault();
    const stage = e.target.getStage();
    const scaleBy = 1.1;
    const oldScale = stage.scaleX();

    const mousePointTo = {
      x: stage.getPointerPosition().x / oldScale - stage.x() / oldScale,
      y: stage.getPointerPosition().y / oldScale - stage.y() / oldScale,
    };

    const newScale = e.evt.deltaY > 0 ? oldScale / scaleBy : oldScale * scaleBy;

    setScale({
      x: newScale,
      y: newScale,
    });

    stage.scale({ x: newScale, y: newScale });

    const newPos = {
      x: -(mousePointTo.x - stage.getPointerPosition().x / newScale) * newScale,
      y: -(mousePointTo.y - stage.getPointerPosition().y / newScale) * newScale,
    };
    stage.position(newPos);
    stage.batchDraw();
  };

  const handleMouseDown = (e) => {
    const stage = e.target.getStage();
    const pointerPosition = stage.getPointerPosition();
    const clickedOnEmpty = e.target === stage;
  
    if (clickedOnEmpty) {
      setSelectedId(null);
      setSelectedType(null); // Add this line
    }
  
    const pos = {
      x: (pointerPosition.x - stage.x()) / stage.scaleX(),
      y: (pointerPosition.y - stage.y()) / stage.scaleY()
    };
  
    if (selectedShape === 'freedraw') {
      setIsDrawing(true);
      setLines([...lines, { id: Date.now(), points: [pos.x, pos.y] }]);
    } else if (selectedShape && selectedShape !== 'hand') {
      setIsDrawing(true);
      setCurrentShape({ id: Date.now(), type: selectedShape, x: pos.x, y: pos.y, width: 50, height: 50, points: [pos.x, pos.y], text: '', image: uploadedImage });
    }
  };
  

  const handleMouseMove = (e) => {
    if (selectedId || !isDrawing) {
      return;
    }

    const stage = e.target.getStage();
    const pointerPosition = stage.getPointerPosition();
    const point = {
      x: (pointerPosition.x - stage.x()) / stage.scaleX(),
      y: (pointerPosition.y - stage.y()) / stage.scaleY()
    };

    if (selectedShape === 'freedraw') {
      const lastLine = lines[lines.length - 1];
      lastLine.points = lastLine.points.concat([point.x, point.y]);
      lines.splice(lines.length - 1, 1, lastLine);
      setLines(lines.concat());
    } else {
      const newShape = { ...currentShape };

      switch (selectedShape) {
        case 'rectangle':
        case 'concrete':
        case 'pool':
          newShape.width = point.x - currentShape.x;
          newShape.height = point.y - currentShape.y;
          break;
        case 'circle':
        case 'tree':
          newShape.width = point.x - currentShape.x;
          newShape.height = point.y - currentShape.y;
          break;
        case 'doubleGate':
        case 'singleGate':
            newShape.width = Math.abs(point.x - currentShape.x);
            newShape.height = Math.abs(point.y - currentShape.y);
            break;
        case 'line':
        case 'fence':
        case 'tearOutFence':
        case 'existingFence':
          newShape.points = [currentShape.x, currentShape.y, point.x, point.y];
          break;
        case 'arrow':
          newShape.points = [currentShape.x, currentShape.y, point.x, point.y];
          break;
        case 'text':
          newShape.width = point.x - currentShape.x;
          newShape.height = point.y - currentShape.y;
          newShape.text = textInput;
          break;
        case 'electricity':
        case 'solarPanel':
        case 'gasMeter':
        case 'dog':
        case 'slideMotor':
        case 'image':
          newShape.width = point.x - currentShape.x;
          newShape.height = point.y - currentShape.y;
          newShape.points = [currentShape.x, currentShape.y, point.x, point.y];
          break;
        case 'map':
          newShape.image = mapImage;
          newShape.width = mapWidth;
          newShape.height = mapHeight;
          break;
        default:
          break;
      }

      setCurrentShape(newShape);
    }
  };

  const handleMouseUp = () => {
    if (isDrawing) {
      if (selectedShape === 'freedraw') {
        setLines([...lines, currentShape]);
      } else {
        setShapes([...shapes, currentShape]);
      }
      setHistory([...history, { shapes: [...shapes], lines: [...lines] }]);
      setRedoHistory([]);
      setCurrentShape(null);
      setIsDrawing(false);
    }
    setSelectedShape('hand');
  };
  
  
  

  const toggleGrid = () => {
    setShowGrid(!showGrid);
  };

  const selectShape = (shape) => {
    if (shape === 'text') {
      setOpen(true);
    } else if (shape === 'map') {
      setMapDialogOpen(true);
    } else {
      setSelectedShape(shape);
      setSelectedId(null);
    }
    handleClose();
  };


  const handleLogin = async()=>{
    const { data, error } = await supabase
  .from('users')
  .select('account_id, token')
  .match({ email:email, password: password })
  console.log(data);
  if(data.length>0){
    setCurrentToken(data[0].token);
    setCurrentLocation(data[0].account_id);
    window.localStorage.setItem("islogged","good");
    window.localStorage.setItem("currentToken",data[0].token);
    window.localStorage.setItem("currentLocation",data[0].account_id);
    setLoginOpen(false);
  }

  else{
    setIsInvalid("Invalid login");
  }
  }


  const handleTextSubmit = () => {
    setShapes([...shapes, {
      id: Date.now(),
      type: 'text',
      x: 100,
      y: 100,
      width: 200,
      height: 30,
      text: textInput,
      fontSize: 20, // Ensure fontSize is set
      padding: 0, // Remove padding
    }]);
    setTextInput('');
    setOpen(false);
    setSelectedShape('hand');
  };

  const handleMapSubmit = () => {
    const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${mapAddress}&zoom=15&size=${mapWidth}x${mapHeight}&maptype=${mapType}&key=YOUR_API_KEY`;
    const img = new window.Image();
    img.src = mapUrl;
    img.onload = () => {
      setMapImage(img);
      setSelectedShape('map');
    };
    setMapDialogOpen(false);
  };

  const undo = () => {
    if (history.length > 0) {
      const newHistory = [...history];
      const lastState = newHistory.pop();
      console.log('Undo last state:', lastState);
      setRedoHistory([...redoHistory, { shapes: [...shapes], lines: [...lines] }]);
      if (lastState) {
        setShapes(lastState.shapes || []);
        setLines(lastState.lines || []);
      }
      setHistory(newHistory);
    } else {
      console.log('Clearing canvas');
      // Clear the canvas if there is nothing in history
      setShapes([]);
      setLines([]);
      setRedoHistory([]);
    }
  };
  
  const redo = () => {
    if (redoHistory.length > 0) {
      const newRedoHistory = [...redoHistory];
      const nextState = newRedoHistory.pop();
      console.log('Redo next state:', nextState);
      setHistory([...history, { shapes: [...shapes], lines: [...lines] }]);
      if (nextState) {
        setShapes(nextState.shapes || []);
        setLines(nextState.lines || []);
      }
      setRedoHistory(newRedoHistory);
    }
  };
  

  const saveCanvas = () => {
    const json = JSON.stringify(shapes);
    localStorage.setItem('canvasShapes', json);
  };

  const exportCanvas = async () => {

    setMainMenu(false);

    const stage = shapeRefs.current[0].getStage();
    const transformers = stage.find('Transformer');
  
    // Hide all transformers
    transformers.forEach(transformer => transformer.hide());
    stage.draw();
  
    // Set background color
    const backgroundLayer = new Konva.Layer();
    const rect = new Konva.Rect({
      x: 0,
      y: 0,
      width: stage.width(),
      height: stage.height(),
      fill: 'white',
    });
    backgroundLayer.add(rect);
    stage.add(backgroundLayer);
    backgroundLayer.moveToBottom();
    stage.draw();
  
    const dataURL = stage.toDataURL({ pixelRatio: 3 });
    console.log(dataURL);

 
const base64 = dataURL.split('base64,')[1]

const { data, error } = await supabase
   .storage
   .from('dev')
   .upload('public/'+currentContact+'.png', decode(base64), {
       contentType: 'image/png'
   })

   if(error){
    const { data, error } = await supabase
  .storage
  .from('dev')
  .update('public/'+currentContact+'.png', decode(base64), {
    contentType: 'image/png'
  })



   }
   
   update("https://qiwqbswgenqoyhpodedr.supabase.co/storage/v1/object/public/dev/public/"+currentContact+".png");
 

   setTimeout(async function(){
    
    const { data1} = supabase
    .storage
    .from('dev')
    .getPublicUrl('public/avatar1.png', {
      download: true,
    })
 
 
    console.log(data1);
    
  },5000);


    // Remove background layer
    backgroundLayer.destroy();
    transformers.forEach(transformer => transformer.show());
    stage.draw();
  


    saveAs(dataURL, 'canvas.jpg');
  };
  



  const update = async(url)=>{

    const options = {
      method: 'PUT',
      url: 'https://services.leadconnectorhq.com/contacts/'+currentContact,
      headers: {
        Authorization: 'Bearer pit-deedd85d-84d2-4536-a7f4-bd1e9c9085b3',
        Version: '2021-07-28',
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      data: {
      
        customFields: [
          {id: 'bR6Ivj2fEjM0WJbOgf6K', field_value: url}
        ],
       
      }
    };
    
    try {
      const { data } = await axios.request(options);
      console.log(data);
    } catch (error) {
      console.error(error);
    }
  }




  const drawGrid = () => {
    const gridSize = 20;
    const width = window.innerWidth;
    const height = window.innerHeight;
    const lines = [];

    for (let i = 0; i < width / gridSize; i++) {
      lines.push(
        <Line
          key={`v-${i}`}
          points={[i * gridSize, 0, i * gridSize, height]}
          stroke="#ddd"
          strokeWidth={1}
        />
      );
    }

    for (let j = 0; j < height / gridSize; j++) {
      lines.push(
        <Line
          key={`h-${j}`}
          points={[0, j * gridSize, width, j * gridSize]}
          stroke="#ddd"
          strokeWidth={1}
        />
      );
    }

    return lines;
  };

  const handleImageUpload = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      const img = new window.Image();
      img.src = reader.result;
      img.onload = () => {
        setUploadedImage(img);
        setShapes([...shapes, {
          id: Date.now(),
          type: 'image',
          x: 100,
          y: 100,
          width: img.width,
          height: img.height,
          image: img,
        }]);
        setSelectedShape('hand');
      };
    };
    reader.readAsDataURL(e.target.files[0]);
  };
  

  const deleteSelectedShape = () => {
    if (selectedId) {
      const newShapes = shapes.filter(shape => shape.id !== selectedId);
      setShapes(newShapes);

      const newLines = lines.filter(line => line.id !== selectedId);
      setLines(newLines);

      setSelectedId(null);
    }
  };

  const handleMenuClick = (event) => {
    setMainMenu(true);
    setAnchorEl(event.currentTarget);
  };

  const handleFenceMenuClick = (event) => {
    setFenceAnchorEl(event.currentTarget);
  };

  const handleGateMenuClick = (event) => {
    setGateAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMainMenu(false);
    setFenceAnchorEl(null);
    setGateAnchorEl(null);
  };

  const renderShape = (shape, index) => {
    if (!shape) {
      console.error('Shape is undefined at index:', index);
      return null;
    }

    const shapeProps = {
      key: shape.id,
      onClick: () => {
        setSelectedId(shape.id);
        setSelectedType('shape'); // Add this line
      },
      onTap: () => {
        setSelectedId(shape.id);
        setSelectedType
        ('shape'); // Add this line
      },
      draggable: true,
      onDragEnd: (e) => {
        const newShapes = shapes.slice();
        newShapes[index] = {
          ...shape,
          x: e.target.x(),
          y: e.target.y(),
        };
        setShapes(newShapes);
      },
      onTransformEnd: (e) => {
        const node = shapeRefs.current[index];
        const newShapes = shapes.slice();
        newShapes[index] = {
          ...shape,
          x: node.x(),
          y: node.y(),
          rotation: node.rotation(),
          width: Math.max(5, node.width() * node.scaleX()), // Ensure minimum width
          height: Math.max(5, node.height() * node.scaleY()), // Ensure minimum height
        };
  
        if (shape.type === 'circle' || shape.type === 'tree') {
          const radius = Math.max(newShapes[index].width, newShapes[index].height) / 2;
          newShapes[index].width = radius * 2;
          newShapes[index].height = radius * 2;
        }
  
        if (shape.type === 'text') {
          newShapes[index].width = node.width();
          newShapes[index].height = node.height();
        }

        if (['line', 'arrow', 'fence', 'tearOutFence', 'existingFence'].includes(shape.type)) {
          const points = node.points();
          newShapes[index].points = points.map((point, idx) => point * (idx % 2 === 0 ? node.scaleX() : node.scaleY()));
          node.scaleX(1);
          node.scaleY(1);
        }
  
         if (shape.type === 'text') {
          const scaleX = node.scaleX();
          const scaleY = node.scaleY();

        newShapes[index].width =  Math.max(5, node.width() * scaleX);
        newShapes[index].height = Math.max(5, node.height() * scaleY);
        newShapes[index].fontSize = shape.fontSize * node.scaleX();

      }
        node.scaleX(1);
        node.scaleY(1);
        setShapes(newShapes);
      },
      ref: (node) => {
        shapeRefs.current[index] = node;
      },
      name: `shape-${shape.id}`,
    };
  
    switch (shape.type) {
      case 'rectangle':
        return <Rect {...shapeProps} x={shape.x} y={shape.y} width={shape.width} height={shape.height} fill="grey" stroke="black" strokeWidth={2} />;
      case 'circle':
        return <Circle {...shapeProps} x={shape.x} y={shape.y} radius={shape.width / 2} fill="green" stroke="black" strokeWidth={2} />;
      case 'line':
        return <Line {...shapeProps} points={shape.points} stroke="black" strokeWidth={2} />;
      case 'arrow':
        return <Arrow {...shapeProps} points={shape.points} stroke="black" fill="black" />;
      case 'text':
          return (
            <Text
              {...shapeProps}
              x={shape.x}
              y={shape.y}
              text={shape.text}
              fontSize={shape.fontSize} // Ensure font size is set
              width={shape.width}
              height={shape.height}
              fill="black"
              align="left"
              verticalAlign="top"
              padding={0} // Remove unwanted padding
              />
          );
        case 'concrete':
        return <Rect {...shapeProps} x={shape.x} y={shape.y} width={shape.width} height={shape.height} fill="gray" stroke="black" strokeWidth={2} />;
      case 'tree':
        return <Circle {...shapeProps} x={shape.x} y={shape.y} radius={shape.width / 2} fill="forestgreen" stroke="black" strokeWidth={2} />;
      case 'pool':
        return <Rect {...shapeProps} x={shape.x} y={shape.y} width={shape.width} height={shape.height} fill="lightblue" stroke="black" strokeWidth={2} />;
      case 'fence':
      case 'tearOutFence':
      case 'existingFence':
        return (
          <Group {...shapeProps}>
            <Line points={shape.points} stroke={shape.type === 'tearOutFence' ? 'red' : 'black'} strokeWidth={2} dash={['tearOutFence', 'existingFence'].includes(shape.type) ? [5, 2] : []} />
            <Circle x={shape.points[0]} y={shape.points[1]} radius={5} fill="white" stroke="red" strokeWidth={2} />
            <Circle x={shape.points[2]} y={shape.points[3]} radius={5} fill="white" stroke="red" strokeWidth={2} />
          </Group>
        );
      case 'singleGate':
        return (
          <Group {...shapeProps} x={shape.x} y={shape.y} width={shape.width}>
            <Arc y={0} angle={90} innerRadius={0} outerRadius={shape.width} rotation={270} fill="transparent" stroke="black" strokeWidth={2} />
          </Group>
        );
      case 'doubleGate':
        return (
          <Group {...shapeProps} x={shape.x} y={shape.y} width={shape.width}>
            <Arc
              x={0}
              y={0}
              angle={90}
              innerRadius={0}
              outerRadius={shape.width / 2}
              rotation={270}
              fill="transparent"
              stroke="black"
              strokeWidth={2}
            />
            <Arc
              x={shape.width * 1}
              y={0}
              angle={90}
              innerRadius={0}
              outerRadius={shape.width / 2}
              rotation={180}
              fill="transparent"
              stroke="black"
              strokeWidth={2}
            />
          </Group>
        );
      case 'electricity':
      case 'solarPanel':
      case 'gasMeter':
      case 'dog':
      case 'slideMotor':
      case 'image':
      case 'map':
        return <KonvaImage {...shapeProps} image={images[shape.type] || shape.image} x={shape.x} y={shape.y} width={shape.width} height={shape.height} />;
        default:
        return null;
    }
  };

  const ref = useRef(null);
  const ref1 = useRef(null);
  const [artists, setArtists] = useState([]);


  const selectItem = (event) => {
    alert(event);
   
  }

const searchNow = async (event) => {
  setArtists([]);
setSearchInput(event);

//window.document.getElementsByClassName("MuiPaper-root")[0].style.zIndex = 2;

//window.document.getElementsByClassName("base-Popper-root")[0].style.zIndex = 0;

const options = {
  method: 'GET',
  url: 'https://services.leadconnectorhq.com/contacts/',
  params: {
    locationId: currentLocation,
    startAfter: '0',
    query: searchInput,
    limit: '5'
  },
  headers: {
    Authorization: 'Bearer '+currentToken,
    Version: '2021-07-28',
    Accept: 'application/json'
  }
};



try {
  const { data } = await axios.request(options);

 if(data.contacts.length>0){
  let asd = data.contacts.length*35;
  setvvv(100+asd);

 }

 let tmp1 = [];

 for(var i=0; i<data.contacts.length; i++){

    let tmp ={ id: data.contacts[i].id, label: data.contacts[i].firstName+" "+data.contacts[i].lastName, phone:searchInput, email:data.contacts[i].email};
    tmp1.push(tmp);


}


/*
setArtists( // Replace the state
  [ // with a new array
    ...artists, // that contains all the old items
    tmp  // and one new item at the end
  ]
); */

setArtists((artists) => artists.concat([ ...tmp1]))


} catch (error) {
  console.error(error);
}



setAnchorEl1(anchorEl2);
  //setMode("none");

};

const options = [
  { label: 'The Godfather', id: 1 },
  { label: 'Pulp Fiction', id: 2 },
];


const handleClick1 = React.useCallback(() => ref.current?.click(), []);
const handleClick3 = React.useCallback(() => ref1.current?.click(), []);

const setSearchOpen1 = ()=>{
  setSearchOpen(true);
  setMode("block");
setTimeout(function(){
  // 
   handleClick1();
  },500);

}

const handleOnChange = (event, value, reason) => {

  setCurrentContact(value.id);
  setSearchOpen(false);
  
};

const [anchorEl1, setAnchorEl1] = useState(null);
const [anchorEl2, setAnchorEl2] = useState(null);




const open1 = Boolean(anchorEl1);
const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
  setAnchorEl2(event.currentTarget);
  setAnchorEl1(event.currentTarget);

};


const handleClick2 = (event: React.MouseEvent<HTMLButtonElement>) => {
  
  handleClick1();
  //setMode("none");
  setAnchorEl1(anchorEl2);
};


const handleClose1 = () => {
  setMode("block");
  setAnchorEl1(null);

};


  return (
    <div>
      <AppBar position="static" style={{ background: 'white', color: 'black' }}>
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Fence Draw
          </Typography>
          <Box display="flex" alignItems="center">
            <IconButton onClick={toggleGrid} style={{ color: 'gray', fontSize: '30px', margin: '0 10px' }}>
              {showGrid ? <GridOffIcon /> : <GridOnIcon />}
            </IconButton>
            <IconButton onClick={() => selectShape('hand')} style={{ color: 'gray', fontSize: '30px', margin: '0 10px' }}>
              <PanToolIcon />
            </IconButton>
            <IconButton onClick={() => selectShape('rectangle')} style={{ color: 'gray', fontSize: '30px', margin: '0 10px' }}>
              <RectangleIcon />
            </IconButton>
            <IconButton onClick={() => selectShape('circle')} style={{ color: 'gray', fontSize: '30px', margin: '0 10px' }}>
              <CircleIcon />
            </IconButton>
            <IconButton onClick={() => selectShape('line')} style={{ color: 'gray', fontSize: '30px', margin: '0 10px' }}>
              <LineWeightIcon />
            </IconButton>
            <IconButton onClick={() => selectShape('arrow')} style={{ color: 'gray', fontSize: '30px', margin: '0 10px' }}>
              <ArrowForwardIcon />
            </IconButton>
            <IconButton onClick={() => selectShape('text')} style={{ color: 'gray', fontSize: '30px', margin: '0 10px' }}>
              <TextFieldsIcon />
            </IconButton>
            <IconButton onClick={() => selectShape('concrete')} style={{ color: 'gray', fontSize: '30px', margin: '0 10px' }}>
              <FormatShapesIcon />
            </IconButton>
            <IconButton onClick={handleFenceMenuClick} style={{ color: 'gray', fontSize: '30px', margin: '0 10px' }}>
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={fenceAnchorEl}
              keepMounted
              open={Boolean(fenceAnchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => selectShape('fence')}>
                Fence
              </MenuItem>
              <MenuItem onClick={() => selectShape('tearOutFence')}>
                Tear Out Fence
              </MenuItem>
              <MenuItem onClick={() => selectShape('existingFence')}>
                Existing Fence
              </MenuItem>
            </Menu>
            <IconButton onClick={handleGateMenuClick} style={{ color: 'gray', fontSize: '30px', margin: '0 10px' }}>
              <GateIcon />
            </IconButton>
            <Menu
              anchorEl={gateAnchorEl}
              keepMounted
              open={Boolean(gateAnchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => selectShape('singleGate')}>
                Single Gate
              </MenuItem>
              <MenuItem onClick={() => selectShape('doubleGate')}>
                Double Gate
              </MenuItem>
            </Menu>
            <IconButton onClick={handleMenuClick} style={{ color: 'gray', fontSize: '30px', margin: '0 10px' }}>
              <MenuIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={mainMenu}
              onClose={handleClose}
            >
  <MenuItem onClick={() => setSearchOpen1()}>
                <SearchIcon style={{ marginRight: '10px' }} /> Search Contact
              </MenuItem>


              <MenuItem onClick={exportCanvas}>
                <ExportIcon  style={{ marginRight: '10px' }} /> Save to Custom Field
              </MenuItem>



              <MenuItem onClick={() => selectShape('tree')}>
                <NatureIcon style={{ marginRight: '10px' }} /> Tree
              </MenuItem>


              <MenuItem onClick={() => selectShape('electricity')}>
                <BoltIcon style={{ marginRight: '10px' }} /> Electricity
              </MenuItem>
              <MenuItem onClick={() => selectShape('solarPanel')}>
                <SolarPowerIcon style={{ marginRight: '10px' }} /> Solar Panel
              </MenuItem>
              <MenuItem onClick={() => selectShape('gasMeter')}>
                <SpeedIcon style={{ marginRight: '10px' }} /> Gas Meter
              </MenuItem>
              <MenuItem onClick={() => selectShape('dog')}>
                <PetsIcon style={{ marginRight: '10px' }} /> Dog
              </MenuItem>
              <MenuItem onClick={() => selectShape('slideMotor')}>
                <DirectionsWalkIcon style={{ marginRight: '10px' }} /> Slide Motor
              </MenuItem>
         
              <MenuItem onClick={() => selectShape('pool')}>
                <PoolIcon style={{ marginRight: '10px' }} /> Pool
              </MenuItem>
              {/* <MenuItem onClick={() => selectShape('map')}>
                <MapIcon style={{ marginRight: '10px' }} /> Address Lookup
              </MenuItem> */}
            </Menu>
            {/* <IconButton onClick={() => selectShape('freedraw')} style={{ color: 'gray', fontSize: '30px', margin: '0 10px' }}>
              <CreateIcon />
            </IconButton> */}
            <IconButton component="label" style={{ color: 'gray', fontSize: '30px', margin: '0 10px' }}>
              <input type="file" hidden onChange={handleImageUpload} />
              <Upload />
            </IconButton>
            <IconButton onClick={exportCanvas} style={{ color: 'gray', fontSize: '30px', margin: '0 10px' }}>
              <ExportIcon />
            </IconButton>
            {/* <IconButton onClick={saveCanvas} style={{ color: 'gray', fontSize: '30px', margin: '0 10px' }}>
              <SaveIcon />
            </IconButton> */}
          </Box>
        </Toolbar>
      </AppBar>



      <Dialog   open={loginOpen} maxWidth='xs' fullWidth >
        <DialogTitle>Login</DialogTitle>
        <DialogContent  >
      
          <TextField
            autoFocus
            margin="dense"
            label="Email"
            type="text"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

    <TextField
            autoFocus
            margin="dense"
            label="Password"
            type="password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />

                <InputLabel error={false} >{isInvalid}
                </InputLabel> 
       
    </DialogContent>
        <DialogActions>
        
          <Button onClick={handleLogin} color="primary">
            Login
          </Button>
        </DialogActions>
      </Dialog>
      




      <Dialog style={{ overflow: "hidden", zIndex:0 }}  open={searchOpen} maxWidth='xs' fullWidth onClose={() => setSearchOpen(false)}
        PaperProps={{
          sx: {
            minHeight: '20%',
            height:50,
            width: 350,
        
          }
        }}
     
        >
        <DialogTitle ref={ref} onClick={handleClick}  >Search Contact</DialogTitle>
        <DialogContent style={{ overflow: "hidden" }}>
        <Typography sx={{ p: 2 }}>
          
<TextField style={{display:mode,  position:"relative", left:-20}}     fullWidth
onClick={handleClick2} />
          </Typography>
    {/*


          <TextField
            autoFocus
            margin="dense"
            label="Text"
            type="text"
            fullWidth
            value={searchInput}
            onChange={(e) => searchNow(e.target.value)}
          />
     
     <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{width: '20%'}}>First Name</TableCell>
        
            <TableCell align="right">Last Name</TableCell>
        
          </TableRow>
        </TableHead>
        <TableBody>
          {artists.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                  
              <TableCell component="th" scope="row" onClick={()=>selectItem(row.firstname)} >
                {row.firstname}
              </TableCell>
              
              <TableCell align="right">{row.lastname}</TableCell>
          
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
              <Button onClick={handleTextSubmit} color="primary">
            Select
          </Button>
    
   */}


     <Popover
       
        open={open1}
        anchorEl={anchorEl1}
       
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }} onClose={handleClose1}
        
        PaperProps={{
          style: {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },}}
      > 
      
       <Typography sx={{ p: 2, width:300, height:vvv }} style = {{overflow:"hidden"}}
       >
  
      <Autocomplete 
            disablePortal
            style = {{overflow:"hidden"}}
            options={artists}
          
            onChange={handleOnChange}
            getOptionLabel={(option) => option.label}
            filterOptions={filterOptions}
          
            renderInput={(params) => <TextField ref={ref1} {...params} label=""  onChange={(e) => searchNow(e.target.value)}/>}
          /> </Typography>
      </Popover>
              </DialogContent>

              
        <DialogActions>
        {/*
          <Button onClick={()=>setSearchOpen(false)}  color="primary">
            Cancel
          </Button>
*/}
        </DialogActions>
      </Dialog>
      
  

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Enter Text</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Text"
            type="text"
            fullWidth
            value={textInput}
            onChange={(e) => setTextInput(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleTextSubmit} color="primary">
            Add Text
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={mapDialogOpen} onClose={() => setMapDialogOpen(false)}>
        <DialogTitle>Address Lookup</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label="Width"
            type="number"
            fullWidth
            value={mapWidth}
            onChange={(e) => setMapWidth(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Height"
            type="number"
            fullWidth
            value={mapHeight}
            onChange={(e) => setMapHeight(e.target.value)}
          />
          <FormControl fullWidth margin="dense">
            <InputLabel>Map Type</InputLabel>
            <Select
              value={mapType}
              onChange={(e) => setMapType(e.target.value)}
            >
              <MenuItem value="roadmap">Roadmap</MenuItem>
              <MenuItem value="satellite">Satellite</MenuItem>
              <MenuItem value="hybrid">Hybrid</MenuItem>
              <MenuItem value="terrain">Terrain</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setMapDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleMapSubmit} color="primary">
            Lookup
          </Button>
        </DialogActions>
      </Dialog>
      <Box position="fixed" left="0" top="50%" display="flex" flexDirection="column" alignItems="center" zIndex="tooltip">
        <IconButton onClick={undo} color="primary" style={{ fontSize: '30px', margin: '10px 0' }}>
          <UndoIcon />
        </IconButton>
        <IconButton onClick={redo} color="primary" style={{ fontSize: '30px', margin: '10px 0' }}>
          <RedoIcon />
        </IconButton>
        <IconButton onClick={deleteSelectedShape} style={{ color: 'gray', fontSize: '30px', margin: '10px 0' }}>
          <DeleteIcon />
        </IconButton>
      </Box>
      <Stage
        draggable={selectedShape === 'hand'}
        width={window.innerWidth}
        height={window.innerHeight}
        onWheel={handleWheel}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        scaleX={scale.x}
        scaleY={scale.y}
        style={{ background: 'white' }}
      >
        <Layer>
          {/* Grid Lines */}
          {showGrid && drawGrid()}

          {/* Draw Static Shapes */}
          {shapes.map((shape, i) => renderShape(shape, i))}

          {/* Draw Current Shape */}
          {currentShape && renderShape(currentShape, -1)}

        {(history.length > 0 && selectedId && selectedType === 'shape') && !['tearOutFence', 'existingFence', 'fence'].includes(shapes.find(shape => shape.id === selectedId).type) && (
  <TransformerComponent selectedShapeName={`shape-${selectedId}`} />
)}


{lines.map((line) => (
  <Line
    key={line?.id}
    id={line.id}
    points={line.points}
    stroke="red"
    strokeWidth={2}
    onClick={() => {
      setSelectedId(line.id);
      setSelectedType('line'); // Add this line
    }}
    isLine
    draggable={true}
  />
))}


        </Layer>
      </Stage>
    </div>
  );
};

export default Canvas;
